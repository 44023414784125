import { Router, Route, Set, Private } from '@redwoodjs/router'
import PageLayout from 'src/layouts/PageLayout'

const Routes = () => {
  return (
    <Router>
      <Set wrap={PageLayout}>
        <Route path="/about" page={AboutPage} name="about" />
        <Route path="/" page={HomePage} name="home" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/signup" page={SignupPage} name="signup" />
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
        <Route path="/user-details" page={UserDetailsPage} name="userDetails" />
        <Route path="/breweries" page={BreweriesPage} name="breweries" />
        <Route path="/beers" page={BeersPage} name="beers" />
        <Route path="/brewery/{id}" page={BreweryPage} name="brewery" />
        <Route path="/beer/{id}" page={BeerPage} name="beer" />

        <Route notfound page={NotFoundPage} />
        <Private unauthenticated="login">
          <Route path="/profile" page={ProfilePage} name="profile" />
        </Private>
      </Set>
    </Router>
  )
}

export default Routes
