import { Link, routes } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'
import { useState } from 'react'

const Navbar = () => {
  const { isAuthenticated, logOut } = useAuth()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  return (
    <header className="sticky top-0 justify-between bg-white border-b border-gray-200 dark:border-gray-600 z-10">
      <nav className="bg-gray-800 dark:bg-gray-900">
        <div className="mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="ml-3">
                <h1 className="text-white hover:text-gray-300 text-2xl font-semibold tracking-tight">
                  <Link to={routes.home()}>Craft Beers</Link>
                </h1>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-900 hover:text-white" --> */}
                  <Link
                    to={routes.beers()}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Beers
                  </Link>

                  <Link
                    to={routes.breweries()}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Breweries
                  </Link>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 items-center pr-2 hidden sm:flex">
              <Link
                to={routes.about()}
                className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                About
              </Link>
              {isAuthenticated ? (
                <>
                  <Link
                    to={routes.profile()}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Profile
                  </Link>
                  <button
                    onClick={logOut}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to={routes.login()}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Log In
                  </Link>
                  <Link
                    to={routes.signup()}
                    className="text-gray-300 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
            <div className="relative flex items-center sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen)
                }}
              >
                <span className="sr-only">Open main menu</span>
                {!menuIsOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div
          className={`sm:hidden ${menuIsOpen ? '' : 'hidden'}`}
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to={routes.beers()}
              className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => setMenuIsOpen(false)}
            >
              Beers
            </Link>

            <Link
              to={routes.breweries()}
              className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => setMenuIsOpen(false)}
            >
              Breweries
            </Link>
            <Link
              to={routes.about()}
              className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => setMenuIsOpen(false)}
            >
              About
            </Link>
            {isAuthenticated ? (
              <>
                <Link
                  to={routes.profile()}
                  className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setMenuIsOpen(false)}
                >
                  Profile
                </Link>

                <button
                  onClick={() => {
                    setMenuIsOpen(false)
                    logOut()
                  }}
                  className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Log Out
                </button>
              </>
            ) : (
              <>
                <Link
                  to={routes.login()}
                  className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setMenuIsOpen(false)}
                >
                  Log In
                </Link>

                <Link
                  to={routes.signup()}
                  className="text-gray-300 hover:bg-gray-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setMenuIsOpen(false)}
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
