const Footer = () => {
  return (
    <footer className="flex bg-gray-50 border-t border-gray-200 h-24 px-4 py-4">
      <div className="mx-2 md:mx-8 my-auto pb-1 align-middle">
        <p className="text-sm text-gray-700">&copy; 2021 Ryosuke Minami</p>
      </div>
    </footer>
  )
}

export default Footer
