import Navbar from 'src/components/Navbar'
import Footer from 'src/components/Footer'

const PageLayout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen dark:bg-gray-900">
        <Navbar />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

export default PageLayout
